.blockquote-footer {
	color: var(--kt-blockquote-footer-color);
}

@for $i from 2 through 5 {
	.dynamic-column-#{$i} {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(($i), 1fr);
		grid-template-rows: repeat(auto-fill, auto);
		grid-row-gap: 3em;
		grid-column-gap: 25px;
	}

	@media (max-width: 991.98px) {
		.dynamic-column-#{$i} {
			display: block;

			>div {
				margin-bottom: 1.25rem !important;
			}
		}
	}
}