$heightSet: 7.4rem;
$btnPadding: 0.5rem 0.6rem;

.file-drop-box{
    color: #b5b5c3;
    border:1px dashed #e5eaee;
    border-radius:0.42rem;
    &:hover{
        cursor: pointer;
    }

    .column-boxed{
        flex-direction: column;

        .btn{
            padding:$btnPadding;
            i{
                padding:0;
                font-size:1.3rem
            }
        }
    }

    .content-box{
        color: #b5b5c3;
        // height:$heightSet;
        
       .option-list-column{
            flex-direction: column;
            height: 100%;
            justify-content: space-around;
        }
    }

    .attached-files{
        color: #464E5F;
        word-break: break-all;
    }

}
.drag-and-drop-box{
   flex-direction: column;
    height: 100%;
    // justify-content: space-around;
    // min-height:$heightSet;
}

.label-file-box{
    //style={{ width: "calc(100% - 10rem)", padding: '0.345rem' }}
    width: calc(100% - 15rem);
    padding-bottom:0.345rem;
}

// .buttons-file-box{
//     .btn{
//         padding:0.3rem 0.6rem;
//         margin-left:0.5rem;
//         max-height: calc(1.1em + 1rem + 2px);

//         &:nth-last-child(){
//             margin-left:0;
//         }

//         i{
//             padding:0;
//             font-size:1.3rem
//         }
//     }    
// }
.file-drop-label-box{
    height:calc(1.063em + 1.11rem); //calc(0.945em + 1rem);
    padding: 0rem 0.65rem;
}

.file-drop-helper{
    height: calc(1.1em + 1rem + 2px) !important;
    width: calc(1.3em + 1.2rem + 2px) !important;
    padding: 0.3rem 0.6rem !important;
    margin-left: 0.5rem !important;
    background-color: #E1E9FF !important;
    border-color: #E1E9FF !important;
}

.custom-title-header{
    height: calc(1.2em + 1.15rem) !important;
}