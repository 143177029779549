//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: 											#ffffff;

// Coca-Cola color system
$coke-red:                                          #f40009;
$coke-red-semidark:                                 #cf0007;
$coke-orange:                                       #e5813e;
$coke-orange-semidark:                              #d27536;
$coke-blue:                                         #6ac9ce;
$coke-blue-semidark:                                #529fa3;
$coke-green:                                        #6ace7f;
$coke-green-semidark:                               #529f63;

// Theme colors
// Primary
$primary:       									$coke-blue;
$primary-active:    								$coke-blue-semidark;
$primary-light:    								    #f1faff;
$primary-light-dark:    							#212e48;
$primary-inverse:  									$white;

// Success
$success:       									$coke-green;
$success-active:    								$coke-green-semidark;
$success-light:    									#e8fff3;
$success-light-dark:    							#1c3238;
$success-inverse:  									$white;

// Info
$info:       									    #7239ea;
$info-active:    									#5014d0;
$info-light:    									#f8f5ff;
$info-light-dark:    								#2f264f;
$info-inverse:  									$white;

// Danger
$danger:       									    $coke-red;
$danger-active:    									$coke-red-semidark;
$danger-light:    									#fff5f8;
$danger-light-dark:    								#3a2434;
$danger-inverse:  									$white;

// Warning
$warning:       									$coke-orange;
$warning-active:    								$coke-orange-semidark;
$warning-light:    									#fff8dd;
$warning-light-dark:    							#392f28;
$warning-inverse:  									$white;