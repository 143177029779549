.table-button-hover:hover {
    background-color: rgba(25, 118, 210, 0.04);
}

.can-hover-open {
    background-color: inherit;

    &:hover {
        background-color: var(--kt-secondary-inverse-dark) !important;
        box-shadow: 0 0 30px rgb(231, 228, 228);
        border-radius: 10px;
        cursor: pointer;
        z-index: 100;
        width: 99.5% !important;
        align-self: center !important;
        font-weight: 600;
    }
}

.cannot-hover-open {
    background-color: transparent;

    &:hover {
        background-color: transparent !important;

        &.row-even {
            background-color: var(--kt-light-active) !important;
        }
    }
}

.row-even {
    background-color: var(--kt-light-active);
}