//
// Input Group
//

// Form control
.input-group {

    position: relative;

    .form-control {
        border-top-right-radius: 0.475rem !important;
        border-bottom-right-radius: 0.475rem !important;
    }

    .form-control:has(+ .input-group-append) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .form-control:has(+ .react-datepicker__tab-loop) {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    
    &.input-group-solid {
        @include border-radius($input-border-radius);

        &.input-group-sm {
            @include border-radius($input-border-radius-sm);
        }

        &.input-group-lg {
            @include border-radius($input-border-radius-lg);
        }

        .input-group-text {
            background-color: var(--kt-input-solid-bg);
		    border-color: var(--kt-input-solid-bg);

            & + .form-control {
                border-left-color: var(--kt-input-border-color);
            }
        }

        .form-control {
            background-color: var(--kt-input-solid-bg);
    		border-color: var(--kt-input-solid-bg);

            & + .input-group-text {
                border-left-color: var(--kt-input-border-color);
            }
        }
    }

}

.invalid-feedback {
    position: absolute;
    bottom: -22px;
    left: 5px;
}