.homepage {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
}
.homepage-background {
    background-color: #151521;
}
.homepage-bg {
    height: 45vh;
    --bs-gutter-x: 0;
    background-position: 0 56%;
    background-size: cover;
}

.homepage-cards-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    // padding: 4rem;
    column-gap: 4rem;
}

.homepage-card-link {
    display: flex;
    flex: 1 1 25%;
}

.homepage-card-container {
    display: flex;
    flex: 1;
    flex-flow: column;
    justify-content: center !important;
    // row-gap: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.bg-homepage-logo {
    font-size: calc((5vh + 2.5vw) * 0.80) !important;
}

.bg-homepage-card-text {
    font-size: calc((1vh + 0.5vw) * 1.2);
    margin-bottom: 0rem !important;
}

.background-text-no-header {
    margin: 7rem 0 0 4rem;
    font-size: calc(2vh + 1vw);
    font-weight: $font-weight-bolder;
}

.background-text {
    margin: 3rem 0 0 4rem;
    font-size: calc(2vh + 1vw);
    font-weight: $font-weight-bolder;
}

.edit-content {
    height: calc(100vh - var(--kt-app-footer-height) - var(--kt-app-header-height) - 4rem);
}

.strech-env-menu {
    align-items: stretch;
    width: 100%;
}

.button-border {
    color: white;
    border-radius: 30%;
    padding: 0.25rem 1rem;
    background-color: $primary;

    &:hover {
        cursor: pointer;
    }
}

.env-header {
    border-top: 3px solid #eff2f5;
    border-bottom: 3px solid #eff2f5;
}

.app-info-header {
    border-bottom: 3px solid #eff2f5;
    text-align: center;
}

@media (max-width: 1350px) {
    .homepage {
        overflow: auto;
    }

    .homepage-cards-container {
        
        flex-wrap: wrap;
        // row-gap: 3rem;
    }

    .homepage-card-link {
        flex: 1 1 40%;
    }

    .homepage-card-container {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

@media (max-width: 700px) {

    .homepage-card-link {
        flex: 1 1 100%;
    }
}

@media (max-height: 800px) {
    .homepage {
        overflow: auto;
    }

    .homepage-cards-container {
        flex-wrap: wrap;
        column-gap: 3rem;
        // row-gap: 3rem;
    }

    .homepage-card-link {
        flex: 1 1 40%;
    }

    .homepage-card-container {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}