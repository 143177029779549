.horizontal-line {
    position: absolute;
    margin-top: 0.75rem;
    top: 50%;
    /* Center the line vertically */
    width: 80%;
    /* Full width of the container */
    border-top: 1px solid #e4e6ef;
    /* To represent the line */
    transform: translateY(-50%);
    /* Ensure the line is exactly at the center of the icon */
}