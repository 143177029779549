//
// Icon
//
// Icon Sizes
$icon-sizes: (
    ss: 0.5rem,
    xs: 0.65rem,
    sm: 0.75rem,
    md: 1.25rem,
    lg: 1.5rem,
    xl: 1.75rem,
    xxl: 2.25rem,

    1x: 1rem,
    2x: 2rem,
    3x: 3rem,
    4x: 4rem,
    5x: 5rem,
    6x: 6rem,
    7x: 7rem,
    8x: 8rem,
    9x: 9rem,
    10x: 10rem
);

// Default icon
i {
    font-size: get($icon-sizes, md);
    color: $text-muted;
}

// Responsive icon sizes
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @each $name, $value in $icon-sizes {
			.icon#{$infix}-#{$name} {
				font-size: $value !important;
		    }
        }
    }
}
