//
// Form Select
//

.form-select {
    box-shadow: var(--kt-dropdown-box-shadow) !important;

    // Solid style
	&.form-select-solid {
		@include placeholder(var(--kt-input-solid-placeholder-color));
		font-size: 14px;
		background-color: $app-header-dark-bg-color;
		color: white;
		border: 0;
		transition: $transition-input;
		padding: 0 1.7rem 0 0.1rem;
		background: url(../../../../../../../public/media/icons/duotune/arrows/arr097.svg) no-repeat 95%;
		-moz-appearance: none; 
		-webkit-appearance: none; 
		background-size: 20px 35px;
		background-position: 100%;
		appearance: none;
		cursor: pointer;

		.dropdown.show > &,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: $app-header-dark-bg-color;
		}
	}

	// Transparent style
	&.form-select-transparent {
		@include placeholder(var(--kt-input-placeholder-color));
		background-color: transparent;
		border-color: transparent;
		color: $input-color;

		.dropdown.show > &,
		&:active,
		&.active,
		&:focus,
		&.focus {
			background-color: transparent;
			border-color: transparent !important;
			color: $input-color;
		}
	}
}

.form-select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
	cursor: pointer;
}

.form-select-wrapper::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #f40009, #e5813e, #6ac9ce);
}
