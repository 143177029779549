// .react-datepicker-wrapper {
//     border: 1px solid #E5EAEE !important;
//     // width: auto !important;
// }

.datepicker-wrapper {
    border: 1px solid #E5EAEE !important;
    height: 43px;
}

.datepicker-wrapper-dark {
    border: 1px solid #2b2b40 !important;
    height: 43px;
}

.react-datepicker-wrapper:active {
    border: 1px solid $primary !important;
    // width: auto !important;
}

.react-datepicker-wrapper.is-invalid {
    border-color: #F64E60 !important;
}

.react-datepicker-wrapper.is-valid {
    border-color: #6ace7f !important;
}

.react-datepicker__input-container {
    height: 100% !important;
    border-color: inherit !important;
}

.react-datepicker__tab-loop:focus-visible {
    outline: none;
    margin-right: -1px;
}

.datepicker-input {
    width: inherit;
    height: 100% !important;
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #464E5F;
    border: none;
    background: none;
}

.datepicker-input::placeholder {
    color: #0e0e0e;
    font-weight: 400;
}

.datepicker-input:focus-visible {
    outline: none;
}

.datepicker-input:disabled {
    background-color: #F3F6F9;
    opacity: 1;
}

.input-group-append {
    margin-left: 0px;
    z-index: 0;
}

.datepicker-input-dark {
    width: inherit;
    height: 100% !important;
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    border: none;
    background: none;
}

.datepicker-input-dark::placeholder {
    color: #ffffff;
    font-weight: 400;
}

.datepicker-input-dark:focus-visible {
    outline: none;
}

.datepicker-input-dark:disabled {
    background-color: #2b2b40;
    opacity: 1;
}

.react-datepicker-popper {
    z-index: 4 !important;
}