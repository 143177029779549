//
// Nav
//

// Basic
.nav {
	--#{$prefix}nav-link-color: var(--kt-nav-link-color);
	--#{$prefix}nav-link-hover-color: var(--kt-nav-link-hover-color);
	--#{$prefix}nav-link-disabled-color: var(--kt-nav-link-disabled-color);
	--#{$prefix}nav-link-font-size: 1.25 rem;
	--#{$prefix}nav-link-font-weight: 600;
	
}
.nav-link {
	--#{$prefix}nav-link-font-size: 1.25 rem;
	@include font-size(var(--#{$prefix}nav-link-font-size));
	font-weight: var(--#{$prefix}nav-link-font-weight);
}

// Tabs
.nav-tabs {
	--#{$prefix}nav-tabs-border-color: var(--kt-nav-tabs-border-color);
	--#{$prefix}nav-tabs-link-hover-border-color: var(--kt-nav-tabs-link-hover-border-color);
	--#{$prefix}nav-tabs-link-active-color: var(--kt-nav-tabs-link-active-color);
	--#{$prefix}nav-tabs-link-active-bg: var(--kt-nav-tabs-link-active-bg);
	--#{$prefix}nav-tabs-link-active-border-color: var(--kt-nav-tabs-link-active-border-color);
	--#{$prefix}nav-tabs-border-width: 3px
}

// Pills
.nav-pills {
	--#{$prefix}nav-pills-link-active-color: var(--kt-nav-pills-link-active-color);
	--#{$prefix}nav-pills-link-active-bg: var(--kt-nav-pills-link-active-bg);
}

// Nav pills
.nav-pills {
	.nav-item {
		margin-right: 0.5rem;

		&:last-child {
			margin-right: 0;
		}
	}
}

// Stretch items
.nav-stretch {
	align-items: stretch;
	padding-top: 0 !important;
	padding-bottom: 0 !important;

	.nav-item {
		display: flex;
		align-items: stretch;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	.nav-link {
		display: flex;
		align-items: center;
	}
}

// Nav group
.nav-group {
	padding: 0.35rem;
	@include border-radius($border-radius);
	background-color: var(--kt-gray-100);

	// Outline nav group
	&.nav-group-outline {	
		background-color: transparent;
		border: 1px solid var(--kt-border-color);	
	}

	// Fluid option
	&.nav-group-fluid {
		display: flex;

		> label,
		> .btn {
			position: relative;
			flex-shrink: 0;
			flex-grow: 1;
			flex-basis: 0;
		}

		> label {
			margin-right: 0.1rem;

			> .btn {
				width: 100%;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}