.service-menu {
    width: 100%;
    // padding: 3rem;
}

.menu-accordion-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 3rem;
}

.menu-card-link {
    display: flex;
    flex: 0 1 15%;
    margin-bottom: 2rem;
}

.menu-card-container {
    display: flex;
    flex: 1;
    flex-flow: column;
    justify-content: center !important;
    row-gap: 2.5rem;
    padding: 4rem;
    // max-width: 20rem;
}

.menu-full-width {
    width: 100%;
}

@media (max-width: 1350px) {


    .menu-accordion-content {
        
        flex-wrap: wrap;
        row-gap: 3rem;
    }

    .menu-card-link {
        flex: 0 1 22%;
    }

}

@media (max-width: 700px) {
    
    .menu-card-link {
        flex: 0 1 45%;
    }
}

@media (max-width: 500px) {
    
    .menu-card-link {
        flex: 1 1 100%;
    }
}
