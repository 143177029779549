//
// Page
//

.app-page {
    display: flex;
}

.form-frame {

    padding: 1.75rem 1.5rem;
    border-top-left-radius: 0.42rem;
    border-top-right-radius: 0.42rem;

    [data-theme=dark] & {
        border: 4px solid $gray-200-dark;
    }

    [data-theme=light] & {
        border: 4px solid $gray-100;
    }
}

.mandatory-disclaimer {
    display: flex;
    justify-content: flex-end;

    i {
        display: block;
        // margin: auto 0.3em;
        margin-right: 0.3rem;
    }
}

.no-left-radius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}