//
// Drawer
//

// Drawer
.drawer {
	display: flex !important;
	overflow: auto;
	z-index: $drawer-z-index;
	position: fixed;
	top: 0;
	bottom: 0;
	background-color: var(--kt-drawer-bg-color);
	transition: transform $drawer-transition-speed ease-in-out !important;

	&.drawer-start {
		left: 0;
		transform: translateX(-100%);
	}

	&.drawer-end {
		right: 0;
		transform: translateX(100%);

		.menu {

			padding: 0 !important;

			.menu-item {

				.menu-link {

					padding-left: 20px;
					border-radius: 0;

					&.active {
						border-left: 4px solid #f10000;
					}
				}
	
				@include menu-link-default-state(
					$title-color: var(--kt-secondary-inverse-dark),
					$icon-color: var(--kt-secondary-inverse-dark),
					$bullet-color: var(--kt-secondary-inverse-dark),
					$arrow-color: var(--kt-secondary-inverse),
					$bg-color: var(--kt-drawer-bg-color),
					$all-links: false
				);
	
				@include menu-link-hover-state(
					$title-color: var(--kt-secondary-inverse-dark),
					$icon-color: var(--kt-secondary-inverse-dark),
					$bullet-color: var(--kt-secondary-inverse-dark),
					$arrow-color: var(--kt-secondary-inverse),
					$bg-color: rgba(var(--kt-gray-500-rgb), 0.2),
					$all-links: false
				);
	
				@include menu-link-show-state(
					$title-color: var(--kt-primary-inverse),
					$icon-color: var(--kt-primary-inverse),
					$bullet-color: var(--kt-primary-inverse),
					$arrow-color: var(--kt-primary-inverse),
					$bg-color: null,
					$all-links: false
				);
	
				@include menu-link-here-state(
					$title-color: var(--kt-primary-inverse),
					$icon-color: var(--kt-primary-inverse),
					$bullet-color: var(--kt-primary-inverse),
					$arrow-color: var(--kt-primary-inverse),
					$bg-color: $app-sidebar-dark-menu-link-bg-color-active,
					$all-links: false
				);
	
				@include menu-link-active-state(
					$title-color: var(--kt-secondary-inverse-dark),
					$icon-color: var(--kt-secondary-inverse-dark),
					$bullet-color: #f10000,
					$arrow-color: var(--kt-secondary-inverse),
					$bg-color: $app-sidebar-dark-menu-link-bg-color-active,
					$all-links: false
				);

				.menu-sub {

					.menu-item {

						.menu-link {

							padding-left: 20px;
							border-radius: 0;

							&.active {
								border-left: 0;
							}
						}
			
						@include menu-link-default-state(
							$title-color: var(--kt-secondary-inverse-dark),
							$icon-color: var(--kt-secondary-inverse-dark),
							$bullet-color: var(--kt-secondary-active),
							$arrow-color: var(--kt-secondary-inverse-dark),
							$bg-color: var(--kt-drawer-bg-color),
							$all-links: false
						);
			
						@include menu-link-hover-state(
							$title-color: var(--kt-secondary-inverse-dark),
							$icon-color: var(--kt-secondary-inverse-dark),
							$bullet-color: var(--kt-primary-inverse),
							$arrow-color: var(--kt-secondary-inverse-dark),
							$bg-color: rgba(var(--kt-gray-500-rgb), 0.2),
							$all-links: false
						);
			
						@include menu-link-show-state(
							$title-color: var(--kt-primary-inverse),
							$icon-color: var(--kt-primary-inverse),
							$bullet-color: var(--kt-primary-inverse),
							$arrow-color: var(--kt-primary-inverse),
							$bg-color: null,
							$all-links: false
						);
			
						@include menu-link-here-state(
							$title-color: var(--kt-primary-inverse),
							$icon-color: var(--kt-primary-inverse),
							$bullet-color: var(--kt-primary-inverse),
							$arrow-color: var(--kt-primary-inverse),
							$bg-color: $app-sidebar-dark-menu-link-bg-color-active,
							$all-links: false
						);
			
						@include menu-link-active-state(
							$title-color: var(--kt-secondary-inverse-dark),
							$icon-color: var(--kt-secondary-inverse-dark),
							$bullet-color: #f10000,
							$arrow-color: var(--kt-secondary-inverse-dark),
							$bg-color: $app-sidebar-dark-menu-link-bg-color-active,
							$all-links: false
						);
					}
				}
			}
		}
	}

	&.drawer-on {
		transform: none;
		box-shadow: var(--kt-drawer-box-shadow);
		transition: transform $drawer-transition-speed ease-in-out !important;
	}

}

// Drawer Overlay
.drawer-overlay {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	z-index: $drawer-z-index - 1;
	background-color: var(--kt-drawer-overlay-bg-color);
	animation: animation-drawer-fade-in $drawer-overlay-animation-speed ease-in-out 1;
}

// Initial state
[data-kt-drawer="true"] {
	display: none;
}

// Animation
@keyframes animation-drawer-fade-in {
	from   { opacity: 0; }
	to { opacity: 1; }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(lg) {
	body[data-kt-drawer="on"] {
		overflow: hidden;
	}
}
