//
// Dark Header(used by Dark Header layout only)
//

[data-kt-app-layout="dark-header"] {
    .app-header {
        background-color: $app-header-dark-bg-color;
        border-bottom: 0 !important;

        .btn-custom {
            @include button-custom-variant(
                $color: #B5B5C3,
                $icon-color: #B5B5C3,
                $border-color: null,
                $bg-color: null,
                $color-active: #B5B5C3,
                $icon-color-active: var(--kt-primary),
                $border-color-active: null,
                $bg-color-active: rgba(63, 66, 84, 0.35)
            );

            &.show {

                border-color: transparent;
                
                .svg-icon {

                    color: #fceeb2;
                }
            }
        }

        .env-indicator {
            font-weight: 700;
            font-size: 20px;
            font-family: "TCCC Unity Condensed", Helvetica, 'sans-serif';
            align-self: center;
            margin-left: 20px;
        }
    }

    // Desktop mode
    @include media-breakpoint-up(lg) {

        // General mode
        .app-header-menu {
            .menu {

                // Menu root links          
                >.menu-item {

                    transition: 0.2s all ease-in-out;
                    border-bottom: 4px solid transparent;
                    border-top: 4px solid transparent;

                    &:hover {
                        background-color: #f10000;
                    }

                    &.active {
                        border-bottom: 4px solid #f10000;
                    }

                    .menu-title {
                        font-weight: 500;
                    }

                    @include menu-link-default-state(
                        $title-color: #ffffff,
                        $icon-color: #C5C5D8,
                        $bullet-color: #787887,
                        $arrow-color: #ffffff,
                        $bg-color: null,
                        $all-links: false
                    );

                    @include menu-link-hover-state(
                        $title-color: var(--kt-primary-inverse),
                        $icon-color: var(--kt-primary-inverse),
                        $bullet-color: var(--kt-primary-inverse),
                        $arrow-color: var(--kt-primary-inverse),
                        $bg-color: $app-sidebar-dark-menu-link-bg-color-active,
                        $all-links: false
                    );

                    @include menu-link-show-state(
                        $title-color: var(--kt-primary-inverse),
                        $icon-color: var(--kt-primary-inverse),
                        $bullet-color: var(--kt-primary-inverse),
                        $arrow-color: var(--kt-primary-inverse),
                        $bg-color: null,
                        $all-links: false
                    );

                    @include menu-link-here-state(
                        $title-color: var(--kt-primary-inverse),
                        $icon-color: var(--kt-primary-inverse),
                        $bullet-color: var(--kt-primary-inverse),
                        $arrow-color: var(--kt-primary-inverse),
                        $bg-color: $app-sidebar-dark-menu-link-bg-color-active,
                        $all-links: false
                    );

                    @include menu-link-active-state(
                        $title-color: var(--kt-primary-inverse),
                        $icon-color: var(--kt-primary-inverse),
                        $bullet-color: var(--kt-primary-inverse),
                        $arrow-color: var(--kt-primary-inverse),
                        $bg-color: $app-sidebar-dark-menu-link-bg-color-active,
                        $all-links: false
                    );

                    .menu-sub {

                        background-color: var(--kt-drawer-bg-color);

                        .menu-item {

                            background-color: var(--kt-drawer-bg-color);

                            .menu-title {
                                font-weight: 600;
                            }
                
                            @include menu-link-default-state(
                                $title-color: var(--kt-secondary-inverse-dark),
                                $icon-color: var(--kt-secondary-inverse-dark),
                                $bullet-color: var(--kt-secondary-active),
                                $arrow-color: var(--kt-secondary-inverse-dark),
                                $bg-color: var(--kt-drawer-bg-color),
                                $all-links: false
                            );
                
                            @include menu-link-hover-state(
                                $title-color: var(--kt-secondary-inverse-dark),
                                $icon-color: var(--kt-secondary-inverse-dark),
                                $bullet-color: var(--kt-primary-inverse),
                                $arrow-color: var(--kt-secondary-inverse-dark),
                                $bg-color: rgba(var(--kt-gray-500-rgb), 0.2),
                                $all-links: false
                            );
                
                            @include menu-link-show-state(
                                $title-color: var(--kt-primary-inverse),
                                $icon-color: var(--kt-primary-inverse),
                                $bullet-color: var(--kt-primary-inverse),
                                $arrow-color: var(--kt-primary-inverse),
                                $bg-color: null,
                                $all-links: false
                            );
                
                            @include menu-link-here-state(
                                $title-color: var(--kt-primary-inverse),
                                $icon-color: var(--kt-primary-inverse),
                                $bullet-color: var(--kt-primary-inverse),
                                $arrow-color: var(--kt-primary-inverse),
                                $bg-color: $app-sidebar-dark-menu-link-bg-color-active,
                                $all-links: false
                            );
                
                            @include menu-link-active-state(
                                $title-color: var(--kt-secondary-inverse-dark),
                                $icon-color: var(--kt-secondary-inverse-dark),
                                $bullet-color: #f10000,
                                $arrow-color: var(--kt-secondary-inverse-dark),
                                $bg-color: $app-sidebar-dark-menu-link-bg-color-active,
                                $all-links: false
                            );
                        }
                    }
                }
            }
        }
    }

    .app-navbar {

        .app-navbar-item {

            .menu-sub {

                background-color: var(--kt-drawer-bg-color);
                width: max-content !important;
                min-width: 150px;
                padding-top: 5px !important;
                padding-bottom: 5px !important;

                .menu-item {

                    background-color: var(--kt-drawer-bg-color);
                    padding: 0 !important;
                    
                    .menu-link {
                        
                        border-radius: 0;
                    }
        
                    @include menu-link-default-state(
                        $title-color: var(--kt-secondary-inverse),
                        $icon-color: var(--kt-secondary-inverse),
                        $bullet-color: var(--kt-secondary-active),
                        $arrow-color: var(--kt-secondary-inverse),
                        $bg-color: var(--kt-drawer-bg-color),
                        $all-links: false
                    );
        
                    @include menu-link-hover-state(
                        $title-color: var(--kt-secondary-inverse-dark),
                        $icon-color: #ffd000,
                        $bullet-color: var(--kt-primary-inverse),
                        $arrow-color: var(--kt-secondary-inverse-dark),
                        $bg-color: rgba(var(--kt-gray-500-rgb), 0.2),
                        $all-links: false
                    );
        
                    @include menu-link-show-state(
                        $title-color: var(--kt-primary-inverse),
                        $icon-color: var(--kt-primary-inverse),
                        $bullet-color: var(--kt-primary-inverse),
                        $arrow-color: var(--kt-primary-inverse),
                        $bg-color: null,
                        $all-links: false
                    );
        
                    @include menu-link-here-state(
                        $title-color: var(--kt-primary-inverse),
                        $icon-color: var(--kt-primary-inverse),
                        $bullet-color: var(--kt-primary-inverse),
                        $arrow-color: var(--kt-primary-inverse),
                        $bg-color: $app-sidebar-dark-menu-link-bg-color-active,
                        $all-links: false
                    );
        
                    @include menu-link-active-state(
                        $title-color: var(--kt-secondary-inverse-dark),
                        $icon-color: var(--kt-secondary-inverse-dark),
                        $bullet-color: var(--kt-secondary-inverse-dark),
                        $arrow-color: var(--kt-secondary-inverse-dark),
                        $bg-color: rgba(var(--kt-gray-500-rgb), 0.5),
                        $all-links: false
                    );
                }
            }
        }
    }
}