.history_frame {
    margin: 1rem 2rem;
}

.history_content_header {
    height: 48px;
    border:#0000001f solid 1px;
    padding: 0 16px;
    background-color: #00000008;
}

.history_content_header_empty_space {
    width: 12px; 
    margin: 12px 0;
}

.history_content_header_items {
    margin: 12px 0 12px 8px;
    display: flex;
    flex-grow: 1;
}

.history_item {
    flex-basis: 35%; 
    padding-right: 30px;
}

.history_last_item {
    flex-basis: 30%; 
}

.history_table_header {
    font-weight: 600;
    color: #b5b5c3;
    text-transform: uppercase;
    border-bottom: 1px solid #e0e0e0;
    padding: 15px 10px;
}

.history_table_content { 
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
}

.history_table_first_item {
    flex-basis: 40%;
    padding-right: 10px;
}

.history_table_second_item {
    flex-basis: 30%;
    padding-right: 10px;
}

.history_table_last_item {
    flex-basis: 30%;
}