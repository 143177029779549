// //
// // Table
// //

// // Table
// .table {
// 	--#{$prefix}table-color: var(--kt-table-color);
// 	--#{$prefix}table-bg: var(--kt-table-bg);
// 	--#{$prefix}table-border-color: var(--kt-table-border-color);
// 	--#{$prefix}table-accent-bg: var(--kt-table-accent-bg);
// 	--#{$prefix}table-striped-color: var(--kt-table-striped-color);
// 	--#{$prefix}table-striped-bg: var(--kt-table-striped-bg);
// 	--#{$prefix}table-active-color: var(--kt-table-active-color);
// 	--#{$prefix}table-active-bg: var(--kt-table-active-bg);
// 	--#{$prefix}table-hover-color: var(--kt-table-hover-color);
// 	--#{$prefix}table-hover-bg: var(--kt-table-hover-bg);

// 	// Fix for BS 5.1.2 update
// 	& > :not(:first-child) {
//     	border-color: transparent;
// 		border-width: 0;
// 		border-style: none;
// 	}
	
// 	>:not(:last-child)>:last-child>* {
// 		border-bottom-color: inherit;
// 	}

// 	tr, th, td {
// 		border-color: inherit;
// 		border-width: inherit;
// 		border-style: inherit;
// 		text-transform: inherit;
// 		font-weight: inherit;
// 		font-size: inherit;
// 		color: inherit;
// 		height: inherit;
// 		min-height: inherit;

// 		&:first-child {
// 			padding-left: 0;
// 		}

// 		&:last-child {
// 			padding-right: 0;
// 		}
// 	}

// 	tfoot,
// 	tbody {
// 		tr:last-child {
// 			border-bottom: 0 !important;

// 			th, td {
// 				border-bottom: 0 !important;
// 			}
// 		}
// 	}

// 	tfoot {
// 		th, td {
// 			border-top: inherit;
// 		}
// 	}

// 	// Rounded
// 	&.table-rounded {
// 		border-radius: $border-radius;
// 		border-spacing: 0;
// 		border-collapse: separate;
// 	}

// 	// Flush
// 	&.table-flush {
// 		tr, th, td {
// 			padding: inherit;
// 		}
// 	}

// 	// Row bordered
// 	&.table-row-bordered {
// 		tr {
// 			border-bottom-width: 1px;
// 			border-bottom-style: solid;
// 			border-bottom-color: var(--kt-border-color);
// 		}

// 		tfoot {
// 			th, td {
// 				border-top-width: 1px !important;
// 			}
// 		}
// 	}

// 	// Row dashed
// 	&.table-row-dashed {
// 		tr {
// 			border-bottom-width: 1px;
// 			border-bottom-style: dashed;
// 			border-bottom-color: var(--kt-border-color);
// 		}

// 		tfoot {
// 			th, td {
// 				border-top-width: 1px !important;
// 			}
// 		}
// 	}

// 	// Row border colors
// 	@each $name, $value in $grays {
// 		&.table-row-gray-#{$name} {
// 			tr { 
// 				border-bottom-color: var(--kt-gray-#{$name});
// 			}
// 		}
// 	}	
// }

// // Layout fixed
// .table-layout-fixed {
// 	table-layout: fixed;
// }

// // Sorting
// .table-sort {
// 	&:after {
// 		opacity: 0;
// 	}
// }

// .table-sort,
// .table-sort-asc,
// .table-sort-desc {
// 	vertical-align: middle;

// 	&:after {
// 		position: relative;
// 		display: inline-block;
// 		width: 0.75rem;
// 		height: 0.75rem;
// 		content: " ";
// 		bottom: auto;
// 		right: auto;
// 		left: auto;
// 		margin-left: 0.5rem;
// 	}		
// }

// .table-sort-asc {
// 	&:after {
// 		opacity: 1;
// 		@include svg-bg-icon(arrow-top, var(--kt-text-muted));
// 	}
// }

// .table-sort-desc {
// 	&:after {
// 		opacity: 1;
// 		@include svg-bg-icon(arrow-bottom, var(--kt-text-muted));
// 	}
// }

// // Loading
// .table-loading-message {
// 	display: none;
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	@include border-radius($border-radius);
// 	box-shadow: var(--kt-table-loading-message-box-shadow);
// 	background-color: var(--kt-table-loading-message-bg);
// 	color: var(--kt-table-loading-message-color);
// 	font-weight: $font-weight-bold;
// 	margin: 0 !important;
// 	width: auto;
// 	padding: 0.85rem 2rem !important;
// 	transform: translateX(-50%) translateY(-50%);
// }

// .table-loading {
// 	position: relative;

// 	.table-loading-message {
// 		display: block;
// 	}	
// }

// // Cell gutters
// @each $breakpoint in map-keys($grid-breakpoints) {
//     @include media-breakpoint-up($breakpoint) {
//         $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

//         @each $name, $value in $gutters {
// 			.table.g#{$infix}-#{$name} {
// 				th, td {
// 					padding: $value;

// 					// Datatables responsive mode fix
// 					&.dtr-control {
// 						padding-left: $value !important;
// 					}
// 				}
//             }

//             .table.gy#{$infix}-#{$name} {
// 				th, td {
// 					padding-top: $value;
// 					padding-bottom: $value;
// 				}
//             }

// 			.table.gx#{$infix}-#{$name} {
// 				th, td {
// 					padding-left: $value;
// 					padding-right: $value;

// 					// Datatables responsive mode fix
// 					&.dtr-control {
// 						padding-left: $value !important;
// 					}
// 				}
//             }

// 			.table.gs#{$infix}-#{$name} {
// 				 th, td {
// 					&:first-child {
// 	   					padding-left: $value;
// 					}

// 					&:last-child {
// 	   					padding-right: $value;
// 					}

// 					// Datatables responsive mode fix
// 					&.dtr-control:first-child {
// 						padding-left: $value !important;
// 					}
// 				}
//             }
//         }
//     }
// }
// Table
$table-bg:                      					transparent !default;
$table-accent-bg:               					$gray-200 !default;
$table-hover-bg:                					$gray-300 !default;
$table-active-bg:               					$table-hover-bg !default;

$table-border-color:            					$gray-200 !default;
$table-head-bg:                 					$gray-100 !default;
$table-head-color:              					$gray-700 !default;
$table-head-font-size:             					1rem !default; // Custom variable
$table-head-font-weight:           					600 !default; // Custom variable

$table-dark-color:            						$white !default;
$table-dark-bg:               						$dark !default;
$table-dark-accent-bg:        						rgba($white, .05) !default;
$table-dark-hover-color:      						$table-dark-color !default;
$table-dark-hover-bg:        						rgba($white, .075) !default;
$table-dark-border-color:     						lighten($table-dark-bg, 7.5%) !default;

$table-head-bg:                                     $gray-100 !default; // Custom variable
$table-compact-head-font-size:                      0.9rem !default; // Custom variable
$table-compact-head-color:                          $text-muted !default; // Custom variable
$table-compact-head-text-transform:                 uppercase !default; // Custom variable
$table-compact-head-font-weight:                    600 !default; // Custom variable
$table-compact-head-font-weight:                    600 !default; // Custom variable
$table-compact-head-letter-spacing:                 0.1rem !default; // Custom variable

.table {
	color: inherit !important;
	thead {
		th, td {
			font-weight: $table-head-font-weight;
			font-size: $table-head-font-size;
			border-bottom-width: 1px;
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}

	&.table-head-borderless {
		thead {
			th, td {
				border-top: 0;
			}
		}
	}

	&.table-head-solid {
		thead {
			th, td {
				background-color: $table-head-bg;
			}
		}
	}

	&.table-light {
		@each $name, $color in $theme-colors{
			&-#{$name}{
				thead{
					tr {
						color: $color;
						background-color: rgba($color, 0.1);
					}
				}
			}
		}
	}

	&.table-head-custom {
		thead {
			tr,th {
				font-weight: $table-compact-head-font-weight;
				color: $table-compact-head-color !important;
				font-size: $table-compact-head-font-size;
				text-transform: $table-compact-head-text-transform;
				letter-spacing: $table-compact-head-letter-spacing;
			}
		}
	}

	&.table-foot-custom {
		tfoot {
			th,td {
				font-weight: $table-compact-head-font-weight;
				color: $table-compact-head-color !important;
				font-size: $table-compact-head-font-size;
				text-transform: $table-compact-head-text-transform;
				letter-spacing: $table-compact-head-letter-spacing;
			}
		}
	}

	&.table-head-bg {
		thead {
			tr,th {
				background-color: $table-head-bg;
				border-bottom: 0;
				letter-spacing: 1px;

				&:first-child {
					border-top-left-radius: $border-radius;
					border-bottom-left-radius: $border-radius;
				}

				&:last-child {
					border-top-right-radius: $border-radius;
					border-bottom-right-radius: $border-radius;
				}
			}
		}
	}

	&.table-foot-bg {
		tfoot {
			th,td {
				border-bottom: 0;
				background-color: $gray-100;

				&:first-child {
					border-top-left-radius: $border-radius;
					border-bottom-left-radius: $border-radius;
				}

				&:last-child {
					border-top-right-radius: $border-radius;
					border-bottom-right-radius: $border-radius;
				}
			}
		}
	}

	&.table-separate {
		th, td {
			border-top: 0;
			border-bottom: 1px solid $table-border-color;

			&:first-child {
				padding-left: 0 !important;
			}

			&:last-child {
				padding-right: 0 !important;
			}
		}

		tfoot {
			th, td {
				border-bottom: 0;
				border-top: 1px solid $table-border-color;
			}
		}

		tbody {
			tr:last-child {
				td {
					border-bottom: 0;
				}
			}
		}
	}
	&.table-bordered {
		tfoot {
			th,td {
				border-bottom: 0;
			}
		}
	}

	&.table-vertical-center {
		th,td {
			vertical-align: middle;
		}
	}
}

.table:not(.table-bordered) {
	thead {
		th, td {
			border-top: 0;
		}
	}
}

.cc-table-hover > tbody > tr:hover {
    cursor: pointer;
	color: black;
    background-color: #f7f7f8;
}

.can-hover-row:hover {
	cursor: pointer;
	color: black;
    background-color: #f7f7f8;
}

.cloud-resources-table {
	width: 100%;
	border-spacing: 0;
	border-collapse: separate;
	border: 1px solid $gray-500;
	border-radius: 0.42rem;
	margin-top: 1.5rem;
	margin-bottom: 3rem;
	th,
	td {
		padding: 0.8rem;
		border-right: 1px solid $gray-500;
		border-bottom: 1px solid $gray-500;
	}

	tr:last-child>td{
		border-bottom: none;
	}

	tr>td:last-child {
		border-right: none;
	}

	tr>th:last-child {
		border-right: none;
	}

	tbody>tr:last-child > th{
		border-bottom: none;
	}
}